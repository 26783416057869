<template>
    <div class="header header-06">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-4">
                    <a href="#" class="logo">
                        <img src="../assets/img/logo-main.png" alt="" />
                    </a>
                </div>
                <div class="col-md-8">
                    <div class="header-right text-right">
                        <a href="mailto:nexdatech@gmail.com">Say hello! to us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Header"
};
</script>

<style scoped>
/* Add any specific styles here */
</style>