<template>
  <div>
    <Header />
    <div class="main-wrapper demo-06">
      <HeroArea />
      <TeamArea />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import HeroArea from "../components/HeroArea.vue";
import TeamArea from "../components/TeamArea.vue";
import Footer from "../components/Footer.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: {
    Header,
    HeroArea,
    TeamArea,
    Footer
  }
};
</script>

<style scoped>
/* Add any specific styles here */
</style>