import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/bootstrap-4.5.0.min.css';
import './assets/css/lineicons.css';
import './assets/css/animate.css';
import './assets/css/style.css';


createApp(App).mount('#app')

