<template>
  <div id="app">
    <main class="main-06">
      <Home />
    </main>
  </div>
</template>

<script>
import Home from "./views/Home.vue";

export default {
  name: "App",
  components: {
    Home
  }
};
</script>

<style>
/* Global styles can go here */
</style>