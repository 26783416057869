<template>
    <div class="team-area">
        <div class="container">
            <div class="row">
                <div class="col-xl-9">
                    <div class="section-title">
                        <h1 class="wow fadeInUp" data-wow-delay=".2s">Our Real Heroes</h1>
                        <h2 class="wow fadeInUp" data-wow-delay=".4s">
                            Meet the brilliant minds driving <br class="d-none d-lg-block" /> innovation and excellence
                            at
                            Nexda.
                        </h2>
                    </div>
                </div>
            </div>
            <div class="team-wrapper">
                <team-member v-for="(member, index) in teamMembers" :key="index" :name="member.name" :role="member.role"
                    :image="member.image" />
            </div>
        </div>
    </div>
</template>

<script>
import TeamMember from "./TeamMember.vue";

export default {
    name: "TeamArea",
    components: {
        TeamMember
    },
    data() {
        return {
            teamMembers: [
                {
                    name: "Somtoochukwu Akosa",
                    role: "Sr. Software Engineer",
                    image: require("../assets/img/somtoo_1.png")
                },
                {
                    name: "Sarah James",
                    role: "Product Designer",
                    image: require("../assets/img/img-2.jpg")
                },
                {
                    name: "Wesly Okere",
                    role: "Frontend Engineer",
                    image: require("../assets/img/img-3.jpg")
                }
            ]
        };
    }
};
</script>

<style scoped>
/* Add any specific styles here */
</style>