<template>
    <div class="footer">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="credit wow fadeInRight" data-wow-delay=".4s">
                        <p class="footer-text">
                            <span class="copyright-symbol">©</span>
                            <span>{{ currentYear }} Nexda Technologies. All rights reserved.</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Footer",
    data() {
        return {
            currentYear: new Date().getFullYear()
        };
    }
};
</script>

<style scoped>
.footer-text {
    display: flex;
    /* Use flexbox for alignment */
    align-items: top;
    /* Center align items vertically */
}

.copyright-symbol {
    font-size: 1.5em;
    /* Adjust the size as needed */
    margin-right: 0.2em;
    margin-top: 0.2em;
    /* Optional: adds a little space between the symbol and text */
}
</style>