<template>
    <div class="team-item wow fadeInUp" data-wow-delay=".2s">
        <div class="team-img">
            <img :src="image" alt="" />
            <ul class="social-links">
                <li><a href="#"><i class="lni lni-facebook-filled"></i></a></li>
                <li><a href="#"><i class="lni lni-twitter-filled"></i></a></li>
                <li><a href="#"><i class="lni lni-instagram-original"></i></a></li>
                <li><a href="#"><i class="lni lni-linkedin"></i></a></li>
            </ul>
        </div>
        <div class="team-info">
            <h4>{{ name }}</h4>
            <p>{{ role }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "TeamMember",
    props: {
        name: String,
        role: String,
        image: String
    }
};
</script>

<style scoped>
/* Add any specific styles here */
</style>